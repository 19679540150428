import * as React from "react"
import * as styles from "../styles/home.module.scss"
import Template from "../components/ui/Template"
import { Button } from "@material-ui/core"
import { Helmet } from "react-helmet"
import { Block } from "@material-ui/icons"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return <Template currentPage="group" lightnessTheme="dark">
      <Helmet>
        <title>Daya Selaras Group - Daya Cipta Kemasindo</title>
      </Helmet>
      <div className={styles.jumbotron} style={{backgroundImage: `url('/static/images/photos/ptdck.jpg')`}} id="ptdck">
        <div className={`${styles.fence} ${styles.fence__row}`}>
          <div className={styles.cksSlide}>
            <img src="/static/images/ptdck-logo.png" className={styles.logo} />
            <div className={styles.title}>
              DAYA CIPTA <br />KEMASINDO
            </div>
            <div className={styles.info}>
              A leading integrated corrugated carton manufacturer in Indonesia. Our commitment is to provide quality corrugated recycled packaging solutions to our customers.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.fullPage} style={{backgroundColor: "#c7d6cd", paddingTop: "32px", paddingBottom: "32px"}}>
          <div className={styles.fence}>
              <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
                  <div className={styles.titleWithDescription} style={{maxWidth: "300px"}}>
                    <div className={styles.titleWithDescription__title} style={{display: "flex", flexDirection: "column"}}><span>PLANT</span> <span>CAPACITY</span></div>
                    <div className={styles.titleWithDescription__description}>Located in 3 locations with 16.000 tons/month production capacity in total.</div>
                  </div>
                  <div className={styles.responsiveImage} style={{display: "block"}}>
                    <img src="/static/images/plant-capacity-2.png" style={{width: "90%", margin: "auto", display: "block"}}></img>
                    <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start", justifyContent: "center"}}>
                      <div style={{width: "300px", maxWidth: "100%", boxSizing: "border-box", padding: "32px 16px", lineHeight: "1"}}>
                          <b>FACTORY I</b>
                          <p>Jl. Aster Jawa No. 42 Daan Mogot Km 19</p>
                          <p>Kel. Jurumudi Baru, Kec. Benda</p>
                          <p>Tangerang 15124</p>
                          <p>Banten - Indonesia</p>
                          <p>Phone: (62-21) 5450071, 5450072, 5417893</p>
                          <p>Fax: (62-21) 5417892, 9244622</p>
                          <iframe width="100%" height="200" frameborder="0" style={{border: 0}} src="https://www.google.com/maps/embed/v1/place?q=pt%20dayacipta%20kemasindo%20daan%20mogot&key=AIzaSyBtWxIHqcQeAlXOSyeDHvladEjK290OeBI" allowfullscreen></iframe>
                      </div>
                      <div style={{width: "300px", maxWidth: "100%", boxSizing: "border-box", padding: "32px 16px", lineHeight: "1"}}>
                          <b>HEAD OFFICE / FACTORY II</b>
                          <p>Jl. Perjuangan Desa Sukadanau</p>
                          <p>Kec. Cibitung</p>
                          <p>Kab. Bekasi Timur 17520 - Indonesia</p>
                          <p>Phone: (62-21) 89109521, 8902573</p>
                          <p>Fax: (62-21) 8902574</p>
                          <p><br /></p>
                          <p><br /></p>
                          <iframe width="100%" height="200" frameborder="0" style={{border: 0}} src="https://www.google.com/maps/embed/v1/place?q=pt%20dayacipta%20kemasindo%20bekasi&key=AIzaSyBtWxIHqcQeAlXOSyeDHvladEjK290OeBI" allowfullscreen></iframe>
                      </div>
                      <div style={{width: "300px", maxWidth: "100%", boxSizing: "border-box", padding: "32px 16px", lineHeight: "1"}}>
                          <b>FACTORY III</b>
                          <p>Jl. Inspeksi Kalimalang, Desa Karang Mulya</p>
                          <p>Kampung Calung, Kec. Teluk Jambe Barat,</p>
                          <p>Kabupaten Karawang 41361 - Indonesia</p>
                          <p>Phone: (62-267) 8457087, 8457088, 8457089</p>
                          <p>Fax: (62-267) 8457086</p>
                          <iframe width="100%" height="200" frameborder="0" style={{border: 0}} src="https://www.google.com/maps/embed/v1/place?q=pt%20dayacipta%20kemasindo%20karawang&key=AIzaSyBtWxIHqcQeAlXOSyeDHvladEjK290OeBI" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div className={styles.fullPage} style={{backgroundColor: "#ffffff"}}>
          <div className={styles.fence} style={{backgroundColor: "#ffffff", paddingTop: "32px", paddingBottom: "32px"}}>
              <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
                  <div className={styles.titleWithDescription} style={{minWidth: "min(100%, 400px)"}}>
                    <div className={styles.titleWithDescription__title}><span>PRODUCT</span> <span>SPECIFICATIONS</span></div>
                  </div>
                  <div className={styles.responsiveImage} style={{backgroundImage: "url('/static/images/ptdck-product.png')"}}></div>
              </div>
          </div>
      </div>
      <div className={styles.jumbotron} style={{backgroundImage: `url('/static/images/photos/qc.jpg')`}}>
        <div className={`${styles.fence} ${styles.fence__row}`} style={{alignItems: "flex-start"}}>
          <div className={styles.cksSlide}>
            <div className={styles.title}>
              QUALITY <br />CONTROL
            </div>
            <div className={styles.info} style={{maxWidth: "700px"}}>
              One of our ways to ensure integrity in producing high-quality products is to perform a series of tests on our products and raw materials. This is done to ensure strength, effectiveness and durability in order for our packaging to hold and protect the products of our clients. We perform several quality checks before, during and after the cartons get off the rollers.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.jumbotron} style={{backgroundImage: `url('/static/images/photos/product-development.jpg')`, paddingTop: "0", paddingBottom: "64px"}} id="ptdck">
        <div className={`${styles.fence} ${styles.fence__row}`} style={{alignItems: "flex-end"}}>
          <div className={styles.cksSlide}>
            <div className={styles.title}>
              PRODUCT <br />DEVELOPMENT
            </div>
            <div className={styles.info} style={{maxWidth: "700px"}}>
              Our designs are the corner stone of our production.We have established our in-house production development team that helps to create new concepts and designs to suit our customer’s packaging needs. The team of structural and graphic designers design the products from conceptual renderings to the final presentation with particular care on customer’s detailed requirements.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.halfPage} style={{backgroundColor: "#ffffff"}}>
          <div className={styles.fence} style={{backgroundColor: "#ffffff", paddingTop: "32px", paddingBottom: "32px", flexWrap: "wrap"}}>
              <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
                  <div className={styles.titleWithDescription} style={{minWidth: "min(100%, 400px)"}}>
                    <div className={styles.titleWithDescription__title}><span>OUR CLIENTS</span></div>
                  </div>
                  <div style={{backgroundImage: "url('/static/images/partners/01.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/02.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/03.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/04.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/05.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/06.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/Heinz_ABC.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/08.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/09.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/10.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/Logo-Mayora-1.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/dmnd.jpg')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/13.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/logo tokopedia.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/TORABIKA.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/VIT.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/SOFTEX.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/SINDE.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/SHOPPE.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/RINNAI.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/KALBE.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/CASTROL.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/KIMBERLY.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/MONDELEZ.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/KSN.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/WILMAR.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />
                  <div style={{backgroundImage: "url('/static/images/partners/ROYAL.gif')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "150px", height: "150px"}} />



              </div>
          </div>
      </div>
      <div className={styles.halfPage} style={{backgroundColor: "#ffffff"}}>
          <div className={styles.fence} style={{backgroundColor: "#ffffff", paddingTop: "32px", paddingBottom: "32px", flexWrap: "wrap"}}>
              <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
                  <div className={styles.titleWithDescription} style={{minWidth: "min(100%, 500px)"}}>
                    <div className={styles.titleWithDescription__title}><span>CERTIFICATIONS</span></div>
                  </div>
                  {/* <div style={{backgroundImage: "url('/static/images/certs/01.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "100px", height: "100px"}} /> */}
                  <div style={{backgroundImage: "url('/static/images/certs/04.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "100px", height: "100px"}} />
                  <div style={{backgroundImage: "url('/static/images/certs/07.jpg')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "100px", height: "100px"}} />
                  <div style={{backgroundImage: "url('/static/images/certs/03.png')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "100px", height: "100px"}} />
                  <div style={{backgroundImage: "url('/static/images/certs/05.jpg')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "100px", height: "100px"}} />
                  {/* <div style={{backgroundImage: "url('/static/images/certs/05.jpg')", backgroundRepeat: "no-repeat", backgroundSize:"contain", backgroundPosition: "center", width: "100px", height: "100px"}} /> */}
              </div>
          </div>
      </div>
    </Template>
  }
}

export default IndexPage
